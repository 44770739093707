module.exports = [{
      plugin: require('/home/runner/work/johaq.com-www/johaq.com-www/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/home/runner/work/johaq.com-www/johaq.com-www/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":925,"linkImagesToOriginal":true,"sizeByPixelDensity":false},
    },{
      plugin: require('/home/runner/work/johaq.com-www/johaq.com-www/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-146439592-1"},
    }]
